.cardHeader {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  & > div:last-child {
    align-self: center;
    margin-bottom: -8px;
    margin-top: -8px;
    margin-right: -16px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding-top: 0;
}

.test {
  background-color: black;
}
