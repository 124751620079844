.modal {
  & div[role="dialog"] {
    position: absolute;
    bottom: 0;
    top: 0;
  }

  .dialogContent {
    padding: 0;

    #content {
      height: 100%;
    }
  }
}

.items > ul {
  padding-bottom: 0;
  padding-top: 0;
}