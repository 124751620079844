@font-face {
    font-family: 'Vinci Sans';
    font-weight: 900;
    src: url(../../resources/fonts/vinci_sans_black.ttf) format('truetype');
}

@font-face {
    font-family: 'Vinci Sans';
    font-weight: bold;
    src: url(../../resources/fonts/vinci_sans_bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Vinci Sans';
    font-weight: bold;
    font-style: italic;
    src: url(../../resources/fonts/vinci_sans_bold_italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Vinci Sans';
    font-weight: 200;
    src: url(../../resources/fonts/vinci_sans_extra_light.ttf) format('truetype');
}

@font-face {
    font-family: 'Vinci Sans';
    font-style: italic;
    src: url(../../resources/fonts/vinci_sans_italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Vinci Sans';
    font-weight: 300;
    src: url(../../resources/fonts/vinci_sans_light.ttf) format('truetype');
}

@font-face {
    font-family: 'Vinci Sans';
    font-weight: 300;
    font-style: italic;
    src: url(../../resources/fonts/vinci_sans_light_italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Vinci Sans';
    font-weight: 500;
    src: url(../../resources/fonts/vinci_sans_medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Vinci Sans';
    src: url(../../resources/fonts/vinci_sans_regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Vinci Serif';
    font-weight: 900;
    src: url(../../resources/fonts/vinci_serif_black.ttf) format('truetype');
}